<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>{{ $t('users.users') }}</v-toolbar-title>
        </v-toolbar>
        <users-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></users-list-filter>
      </template>
      <template v-slot:item.lastActiveDate="{ item }">
        {{ item.lastActiveDate | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="canUpdate"
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updateUser(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="canUpdate && !item.locked"
          x-small
          fab
          depressed
          color="success"
          class="mr-1"
          @click="lockUser(item.id)"
        >
          <v-icon>mdi-lock-open-variant</v-icon>
        </v-btn>
        <v-btn
          v-if="item.role !== userRoles.superAdmin && item.locked"
          x-small
          fab
          depressed
          color="warning"
          class="mr-1"
          @click="unlockUser(item.id)"
        >
          <v-icon>mdi-lock</v-icon>
        </v-btn>
        <v-btn
          v-if="canDelete"
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteUser(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_USERS,
  CHANGE_ROLE,
  LOCK_USER,
  UNLOCK_USER,
  DELETE_USER
} from '../store/types';
import UsersListFilter from './UsersListFilter';
import UpdateUserModal from './UpdateUserModal';
import ConfirmModal from '../../shared/components/ConfirmModal';
import userRoles from '../../../enums/user.roles';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import resources from '../../../enums/resources';
import aclMixin from '../../shared/mixins/acl.mixin';

export default {
  name: 'users-list',
  components: {
    UsersListFilter
  },
  computed: {
    ...mapGetters({
      items: 'users',
      totalItems: 'totalUsers',
      currentUser: 'currentUser'
    })
  },
  mixins: [aclMixin, listItemsMixin],
  data() {
    return {
      loading: true,
      feature: resources.users,
      headers: [],
      userRoles
    };
  },
  created() {
    this.setHeaders();
  },
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t('common.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('common.email'),
          value: 'email',
          sortable: false
        },
        {
          text: this.$i18n.t('common.role'),
          value: 'role',
          sortable: false
        },
        {
          text: this.$i18n.t('common.lastActiveDate'),
          value: 'lastActiveDate',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdAt'),
          value: 'createdAt',
          sortable: false
        }
      ];

      if (this.currentUser.role === userRoles.superAdmin) {
        this.headers.push({
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        });
      }
    },
    async getItems(params) {
      this.loading = true;
      await this.$store.dispatch(GET_USERS, params);
      this.loading = false;
    },
    async updateUser(user) {
      const payload = await this.$root.$modal.show(UpdateUserModal, {
        selectedUser: { ...user }
      });

      if (!payload) return;

      await this.$store.dispatch(CHANGE_ROLE, { id: user.id, role: payload });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('users.roleChangedSuccessfully')
      });
    },
    async lockUser(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('users.lockConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(LOCK_USER, id);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('users.lockSuccess')
      });
    },
    async unlockUser(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('users.unlockConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(UNLOCK_USER, id);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('users.unlockSuccess')
      });
    },
    async deleteUser(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('users.deleteConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_USER, id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('users.deleteSuccess')
      });
    }
  }
};
</script>
