import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['acl']),
    canRead() {
      return (
        this.acl &&
        (this.acl[this.feature].indexOf('r') > -1 ||
          this.acl[this.feature].indexOf('rw') > -1)
      );
    },
    canWrite() {
      return (
        this.acl &&
        (this.acl[this.feature].indexOf('w') > -1 ||
          this.acl[this.feature].indexOf('rw') > -1)
      );
    },
    canUpdate() {
      return this.acl && this.acl[this.feature].indexOf('u') > -1;
    },
    canDelete() {
      return this.acl && this.acl[this.feature].indexOf('d') > -1;
    }
  }
};
