<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <users-list></users-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UsersList from '../components/UsersList';

export default {
  name: 'users-page',
  components: {
    UsersList
  }
};
</script>
