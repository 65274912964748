module.exports = {
  users: 'users',
  gamTasks: 'gamTasks',
  quotes: 'quotes',
  facts: 'facts',
  questions: 'questions',
  specialBonuses: 'specialBonuses',
  boards: 'boards',
  figures: 'figures',
  avatars: 'avatars',
  avatarCategories: 'avatarCategories',
  gemsPlans: 'gemsPlans',
  gamPushNotifications: 'gamPushNotifications',
  acl: 'acl'
};
