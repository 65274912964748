<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light">
        {{ $t('users.update') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="my-1">
        <span class="subtitle-1">{{ $t('common.name') }}: </span>
        <span class="subtitle-1 black--text">{{ selectedUser.name }}</span>
      </div>
      <div class="my-1">
        <span class="subtitle-1">{{ $t('common.email') }}: </span>
        <span class="subtitle-1 black--text">{{ selectedUser.email }}</span>
      </div>
      <div class="my-1">
        <span class="subtitle-1">{{ $t('common.createdAt') }}: </span>
        <span class="subtitle-1 black--text">{{ selectedUser.createdAt }}</span>
      </div>
      <div class="my-1">
        <span class="subtitle-1">{{ $t('common.role') }}:</span>
        <v-select
          v-model="user.role"
          :items="roles"
          :label="$t('common.role')"
          solo
        ></v-select>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import userRoles from '../../../enums/user.roles';

export default {
  name: 'update-user-modal',
  props: {
    selectedUser: Object
  },
  data() {
    return {
      user: {
        role: null
      },
      roles: [userRoles.admin, userRoles.moderator, userRoles.user]
    };
  },
  created() {
    this.user.role = this.selectedUser.role;
  },
  methods: {
    save() {
      if (this.user.role !== this.selectedUser.role) {
        this.$emit('close', this.user.role);
      } else {
        this.$emit('close');
      }
    }
  }
};
</script>
